import { PiLockBold } from 'react-icons/pi'
import { LinkButton } from '#app/components/ui/button'

export function Unauthorized() {
	return (
		<div className="flex h-full flex-1 flex-col items-center justify-center">
			<PiLockBold className="h-20 w-20" />
			<h1 className="pt-8 text-h2">Access Denied</h1>
			<p className="pt-4 text-lg text-text-gray">
				Sorry, you are not authorized to view this page.
			</p>
			<div className="mt-4 flex items-center justify-center gap-x-6">
				<a
					href="https://moonhub-internal.slack.com/archives/C050FLXNZEW"
					target="_blank"
					className="text-sm text-text-gray"
					rel="noreferrer"
				>
					Contact support via slack #internal-product-feedback if you
					believe this is a mistake
				</a>
			</div>
			<div className="pt-8">
				<LinkButton to="/logout" variant={'default'} size={'lg'}>
					Log Out
				</LinkButton>
			</div>
		</div>
	)
}
